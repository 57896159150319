import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image} from 'react-bootstrap';
import { useNavigate, Link} from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined, isEmpty } from 'lodash';
import {FiArrowDownRight, FiEdit, FiLink2, FiTrash, FiTv} from 'react-icons/fi';
import Confirmation from '../../Confirmation';
import { searchPlansRequest } from '../../../db/action/plans';
import { listCategoriesRequest } from '../../../atom/categories/action'
import PageHeader from '../../PageHeader';
import Error from '../../Error';
import CitiesJson from '../../../data/Cities/index.json';
import { TbCalendarPlus, TbCalendarStats, TbSearch, TbLayersOff } from 'react-icons/tb';

const venueOptions = [{name: 'online', value: 'online'}, {name: 'physical', value: 'physcal'}, {name: 'near to me', value:'near'} ];
const limit = 12;
const skip = 0;
const HomeSearch = (props) => {
    const {PlansSearch, setIndex, place} = props;
    const navigate = useNavigate();
    const [query, setQuery] = useState({
    });
    const [name, setName] = useState([]);
    const [venue, setVenue] = useState([]);
    const [city, setCity] = useState([]);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    useEffect(() => {
        setLoading(true);
        props.listCategoriesRequest({
            is_active: true
        });
    }, []);

    useEffect(() => {
        const object = {...query};
        object.latitude = props.place && props.place.latitude;
        object.longitude = props.place && props.place.longitude;
        object.limit = limit;
        object.skip = skip;
        setQuery(object);
    }, [props.place]);

    useEffect(() => {
        if(props.CategoriesList.categories && props.CategoriesList.categories.length > 0) {
            setLoading(true);
            props.searchPlansRequest({
                type: 'near to me',
                categories_id: props.CategoriesList.categories[0].categories_id,
                latitude: query.latitude,
                longitude: query.longitude,
                limit,
                skip
            });
        }
    }, [props.CategoriesList]);

    
    useEffect(() => {
        const {success, code, message } = PlansSearch;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [PlansSearch]);
    
   
    const handleChange = (e) => {
        const {name, value} = e.target;
        const object = {...query};
        object[name] = value;
        setQuery(object);
    };

    const handleCategories = (valueMixed) => {
        const object = {...query};
        object.categories_id = valueMixed.length ? valueMixed[0].categories_id: [];
        const name = valueMixed.length ? [valueMixed[0].name]: [];
        setQuery(object);
        setName(name);
    };

    const handleVenue = (valueMixed) => {
        const object = {...query};
        object.type = valueMixed.length ? valueMixed[0].name: [];
        let venue = valueMixed.length ? [valueMixed[0].name]: [];
        setQuery(object);
        setVenue(venue);
    };

    const handleLocation = (valueMixed) => {
        const object = {...query};
        object.city = valueMixed.length ? valueMixed[0].name: [];
        let city = valueMixed.length ? [valueMixed[0].name]: [];
        setQuery(object);
        setCity(city); 
    };

    const handleSubmit = () => {
        if (isEmpty(query.type) === true) {
            setInfo({
                ...info,
                  content: 'Please Select Venue type',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(query.city) === true && query.type === 'physical') {
            setInfo({
                ...info,
                  content: 'Please Select nearest city',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(query.categories_id) === true) {
            setInfo({
                ...info,
                  content: 'Please Select Categories id',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }

        setLoading(true);
        props.searchPlansRequest(query);
    }

    return (
        <React.Fragment>
            <Row className={'justify-content-center mt-5 mb-5'}>
                <Col md={7} sm={12} lg={7}>
                {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}    
                <hr />
                <Form.Group className={'mb-3 bg-transparent ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-transparent border-0'}>
                        <TbCalendarPlus size={20} color={'white'} />
                    </InputGroup.Text>
                    <Typeahead
                        size={'lg'}
                        multiple={false}
                        id='ew-phone-code'
                        className={'form-control border-0 bg-transparent'}
                        labelKey="name"
                        onChange={(value) => handleCategories(value)}
                        options={props.CategoriesList.categories || []}
                        placeholder="Categories"
                        selected={name}
                    />
                    <InputGroup.Text className={'bg-transparent border-0'}><TbCalendarStats size={20}  color={'white'} /></InputGroup.Text>
                    <Typeahead
                        size={'lg'}
                        multiple={false}
                        id='ew-venue-option'
                        className={'form-control border-0 bg-transparent'}
                        labelKey="name"
                        onChange={(value) => handleVenue(value)}
                        options={venueOptions}
                        placeholder="Venue"
                        selected={venue}
                    />
                    { query.type === venueOptions[1].name && 
                    <Typeahead
                    multiple={false}
                    id='ew-location'
                    style={{width: '30%', height: 45}}
                    className={'border-0 pt-2'}
                    labelKey="name"
                    onChange={(value) => handleLocation(value)}
                    options={CitiesJson}
                    placeholder="Locations"
                    selected={city}
                />
                     }
                        <InputGroup.Text className={'bg-transparent border-0'}>  
                        <Button className={'btn btn-sm btn-primary text-right'} 
                        onClick={() => handleSubmit()}><small><TbSearch size={20} color={'white'} /></small></Button>
                        </InputGroup.Text> 
                    </InputGroup>
                </Form.Group>
                </Col>
            </Row>        
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      place: state.place && state.place.place, 
      CategoriesList: state.categories.list || {}, 
      PlansSearch: state.plans.search || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchPlansRequest,
        listCategoriesRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(HomeSearch);