import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Row, Card, Table, Image} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {TbCircleDot} from 'react-icons/tb';
import Confirmation from '../../Confirmation';
import { businessEarningsRequest, businessEarningsHistoryRequest } from '../../../db/action/earnings';
import { userBranchRequest } from '../../../db/action/branch'
import PageHeader from '../../PageHeader';
import Error from '../../Error';
const LEarnings = (props) => {
    const {HistoryList, login, Earnings, BranchUser} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    useEffect(() => {
        setLoading(true);
        props.userBranchRequest({
            created_by: login.user_id
        });
    }, []);

    useEffect(() => {
        const {success, code, message, user} = BranchUser;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            if(user) {
                setLoading(true);
                props.businessEarningsRequest({
                    branch_id: user.branch_id
                });
                props.businessEarningsHistoryRequest({
                    branch_id: user.branch_id
                });
            }
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [BranchUser]);


    useEffect(() => {
        const {success, code, message} = HistoryList;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [HistoryList]);

    if (Earnings.earnings && Number(Earnings.earnings.balance) === 0) {
        return (<Confirmation title={'My Earnings'} type={'LEarnings'} />);
    }

    return (
        <React.Fragment>
        <PageHeader title={''} show={loading} className={'mb-4'}/>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <Card>
        {Earnings.earnings && Earnings.earnings.balance  && <Card.Header className={'p-2 bg-white text-end'}>
            <h4>{Earnings.earnings.balance}{' '}<TbCircleDot /></h4>
        </Card.Header>}
        {Earnings.earnings && Earnings.earnings.balance && <Card.Body>
        <Row>
            <Table responsive="md">
                <thead>
                    <tr>
                        <th></th>
                        <th>consumer</th>
                        <th>Balance</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {HistoryList.history && HistoryList.history.map((item, key) => {
                    return (
                        <tr key={key}>
                            <td><Image src={'https://placehold.co/20x20'} roundedCircle/></td>
                            <td>{item.consumer && item.consumer.firstName + ' ' + item.consumer.lastName}</td>
                            <td>{item.balance}</td>
                            <td>{item.type}</td>
                            <td>{item.created_date}</td>
                        </tr>
                    )
                    })}    
                    </tbody>
            </Table>
        </Row>
        </Card.Body>}
        </Card>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      Earnings: state.earnings.business || {},
      HistoryList: state.earnings.businessHistory || {},
      BranchUser: state.branch.user || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        userBranchRequest,
        businessEarningsRequest, 
        businessEarningsHistoryRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(LEarnings);