import {Route, Routes, Navigate} from 'react-router-dom';
import Login from '../../layout/login';
import RegisterUser from '../../layout/registerUser';
import ForgetPassword from '../../layout/forget';
import ActivateUser from '../../layout/activateUser';
import Place from '../../layout/place';
import Dashboard from '../../layout/dashboard';
import Menu from '../../layout/menu';
import Profile from '../../layout/profile';
import File from '../../layout/file';
import Categories from '../../atom/categories/layout';
import Companies from '../../layout/companies';
import Branch from '../../layout/branch';
import Plans from '../../layout/plans';
import Schedule from '../../layout/schedule';
import Wallet from '../../layout/wallet';
import Earnings from '../../layout/earnings';
import Revenue from '../../layout/revenue';
import Purchase from '../../layout/purchase';
import Orders from '../../layout/orders';
import Charge from '../../layout/charge';
import PurchaseStatus from '../../layout/purchase/status';
import UpdatePassword from '../../layout/updateUser';

const Routers = ({login}) => {

    const _loadDefault = () => {
        if(login.is_admin === true)
            return <Categories />;
        if(login.is_partner === true)
            return <Companies />;
        if(login.is_customer === true)
            return <Purchase />;

    }
    return ( 
    <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='login' element={<Login />} />
        <Route path='register' element={<RegisterUser />} />
        <Route path='forget' element={<ForgetPassword />} />
        <Route path='active' element={<ActivateUser />} />
        <Route path='place' element={<Place />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='plans/:plans_id' element={<Plans />} />
        <Route path='menu' element={<Menu />}>
            <Route index element={_loadDefault()} />
            <Route path='categories' element={<Categories />} />
            <Route path='companies' element={<Companies isMenu />} />
            <Route path='branch' element={<Branch />} />
            <Route path='plans' element={<Plans />} />
            <Route path='plans/:plans_id' element={<Plans />} />
            <Route path='schedule' element={<Schedule />} />
            <Route path='wallet' element={<Wallet />} />
            <Route path='earnings' element={<Earnings />} />
            <Route path='revenue' element={<Revenue />} />
            <Route path='charge' element={<Charge />} />
            <Route path='purchase' element={<Purchase />} />
            <Route path='orders' element={<Orders />} />
            <Route path='purchase/status' element={<PurchaseStatus />} />
            <Route path='profile' element={<Profile menu />} />
            <Route path='update' element={<UpdatePassword />} />
            <Route path='file' element={<File/>} />
            <Route path='*' element={<Navigate to={'/'} />} />
        </Route>
        <Route path='*' element={<Navigate to={'/'} />} />
    </Routes>
    );
}
export default Routers;