import React, {useState, useEffect} from 'react';
import {Col, Row, InputGroup, Form, Button, Card} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined} from 'lodash';
import { updatePasswordRequest } from '../../db/action/updatePassword';
import { HiOutlineMail } from 'react-icons/hi';
import PageHeader from '../PageHeader';
import Error from '../Error';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

const UpdatePassword = (props) => {
    const {update} = props;
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    const [user, setUser] = useState({
        email:props.login.email,
        password:''
    });
    const handleChange = (e) => {
        const {name, value} = e.target;
        const object = user;
        object[name] = value;
        setUser(object);
    };

    const handleUpdatePassword = () => {
        if (isStrongPassword(user.password) === false) {
            setInfo({
                ...info,
                content: 'Invalid Password: minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1',
                variant: 'warning'
            });
            setShowError(true);
            return;  
        }
       setLoading(true);
       props.updatePasswordRequest(user);
    }

    useEffect(() => {
        const {success, message, code } = update;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: message,
                    variant: 'success'
            });
        } else {
            setInfo({
                ...info,
                content: `${code} - ${message}`,
                variant: 'warning'
            });
        }
        setShowError(true);
        setLoading(false);     
    }, [update]);

    return (
        <React.Fragment>
        <PageHeader title={'Update Password'} show={loading} className={'mb-4'}/>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <Card border={'light'} className={'border-0'}>
            <Form.Group className={'mb-2 border ew-border-gradient'}>
                <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiOutlineMail /></InputGroup.Text>
                    <Form.Control size={'lg'} placeholder={'Password'} className={'border-0'} required
                        defaultValue={user.password} name={'password'} onChange={(event) => handleChange(event)} /> 
                </InputGroup>
            </Form.Group>
            <Form.Group className={'mt-2'}>
                <Row>
                    <Col md={4}>
                    <Button onClick={handleUpdatePassword}>submit</Button>
                    </Col>
                </Row>
            </Form.Group>
        </Card>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      update: state.user.update || {}
    }
  };
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updatePasswordRequest,
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);