import https from '../../axios';

export const doInsertBranch = async (params) => {
    const {
        name, companies_id, code, summary, payment_upi, url, video_url,
        phoneCode, phone, email, country,line1,line2, city,created_by,
        location } = params;
    try {
        return await https.post('/branch/insert', {
            name, companies_id, code, summary, payment_upi, url, video_url,
        phoneCode, phone, email, country,line1,line2, city,created_by,
        location
        });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doGetBranch = async (params) => {
    const { branch_id } = params;
    try {
        return await https.post('/branch/get', { branch_id});
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doUserBranch = async (params) => {
    const { created_by } = params;
    try {
        return await https.post('/branch/user', { created_by });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doListBranch = async (params) => {
    try {
        return await https.post('/branch/list', params);
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doCompaniesBranch = async (params) => {
    const { companies_id } = params;
    try {
        return await https.post('/branch/companies', {companies_id});
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doRemoveBranch = async (params) => {
    const { branch_id, is_deleted } = params;
    try {
        return await https.post('/branch/remove', { branch_id, is_deleted });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doUpdateBranch = async (params) => {
    const {
        name, companies_id, code, summary, payment_upi, url, video_url,
        phoneCode, phone, email, country,line1,line2, city,created_by,
        location, branch_id
    } = params;
    try {
        return await https.post('/branch/update', {
            name, companies_id, code, summary, payment_upi, url, video_url,
        phoneCode, phone, email, country,line1,line2, city,created_by,
        location, branch_id
        });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doSearchBranch = async (params) => {
    const {
        isKeyword, isLocation, latitude, longitude, query } = params;
    try {
        return await https.post('/branch/search', {
            isKeyword, isLocation, latitude, longitude, query
        });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}
