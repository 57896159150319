import {call, put, takeLatest} from 'redux-saga/effects';
import {
    INSERT_BRANCH_REQUEST,
    INSERT_BRANCH_SUCCESS,
    SEARCH_BRANCH_REQUEST,
    SEARCH_BRANCH_SUCCESS,
    UPDATE_BRANCH_REQUEST,
    UPDATE_BRANCH_SUCCESS,
    GET_BRANCH_REQUEST,
    GET_BRANCH_SUCCESS,
    USER_BRANCH_REQUEST,
    USER_BRANCH_SUCCESS,
    LIST_BRANCH_REQUEST,
    LIST_BRANCH_SUCCESS,
    COMPANIES_BRANCH_REQUEST,
    COMPANIES_BRANCH_SUCCESS,
    REMOVE_BRANCH_REQUEST,
    REMOVE_BRANCH_SUCCESS
} from '../../actionTypes/branch';

import { doInsertBranch, doGetBranch, doSearchBranch,
    doListBranch, doRemoveBranch, doUpdateBranch,
    doCompaniesBranch, doUserBranch } from '../../../com/branch';
    
    import { doGetCompanies } from '../../../com/companies';
    
function* insertBranchFetch(params) {
    const json = yield call(doInsertBranch, params.payload);   
    yield put({ type: INSERT_BRANCH_SUCCESS, payload: json.data});
}

function* searchBranchFetch(params) {
    const json = yield call(doSearchBranch, params.payload);   
    yield put({ type: SEARCH_BRANCH_SUCCESS, payload: json.data});
}

function* updateBranchFetch(params) {
    const json = yield call(doUpdateBranch, params.payload);   
    yield put({ type: UPDATE_BRANCH_SUCCESS, payload: json.data});
}

function* getBranchFetch(params) {
    const json = yield call(doGetBranch, params.payload);   
    const companiesResponse =  yield call(doGetCompanies, {
        companies_id: json.data && json.data.branch.companies_id
    });
    json.data.branch.companies = companiesResponse.data.company; 
    yield put({ type: GET_BRANCH_SUCCESS, payload: json.data});
}

function* userBranchFetch(params) {
    const json = yield call(doUserBranch, params.payload);   
    yield put({ type: USER_BRANCH_SUCCESS, payload: json.data});
}

function* listBranchFetch(params) {
    const json = yield call(doListBranch, params.payload);
    yield put({ type: LIST_BRANCH_SUCCESS, payload: json.data});
}

function*  companiesBranchFetch(params) {
    const json = yield call(doCompaniesBranch, params.payload);   
    yield put({ type: COMPANIES_BRANCH_SUCCESS, payload: json.data});
}

function* removeBranchFetch(params) {
    const json = yield call(doRemoveBranch, params.payload);   
    yield put({ type: REMOVE_BRANCH_SUCCESS, payload: json.data});
}

export function* insertBranchSaga() {
    yield takeLatest(INSERT_BRANCH_REQUEST, insertBranchFetch)
}

export function* searchBranchSaga() {
    yield takeLatest(SEARCH_BRANCH_REQUEST, searchBranchFetch)
}

export function* updateBranchSaga() {
    yield takeLatest(UPDATE_BRANCH_REQUEST, updateBranchFetch)
}

export function* removeBranchSaga() {
    yield takeLatest(REMOVE_BRANCH_REQUEST, removeBranchFetch)
}

export function* getBranchSaga() {
    yield takeLatest(GET_BRANCH_REQUEST, getBranchFetch)
}

export function* userBranchSaga() {
    yield takeLatest(USER_BRANCH_REQUEST, userBranchFetch)
}

export function* companiesBranchSaga() {
    yield takeLatest(COMPANIES_BRANCH_REQUEST, companiesBranchFetch)
}

export function* listBranchSaga() {
    yield takeLatest(LIST_BRANCH_REQUEST, listBranchFetch)
}