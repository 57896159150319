import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, FormControl, Form, Button, Card, Table, Image} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead';
import currencyCodeJson from '../../../data/Currency/index.json';
import { updateScheduleRequest, initScheduleRequest, plansScheduleRequest } from '../../../db/action/schedule';
import SPlans from '../../Plans/Search';
import Confirmation from '../../Confirmation';
import { HiArchive, HiArrowCircleRight, HiOutlineMail } from 'react-icons/hi';
import { CgRename, CgShortcut, CgAdidas, CgAlignMiddle, CgArrowLeftR, CgAirplane, CgArrowLongRightR, CgArrowBottomRight, CgCalendar } from 'react-icons/cg';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { FcPhone, FcGlobe, FcLink } from 'react-icons/fc';
import {TbGenderEpicene} from 'react-icons/tb';
import {isUndefined, isEmpty } from 'lodash';
import isNumeric from 'validator/es/lib/isNumeric';
import isEmail from 'validator/es/lib/isEmail';
import PageHeader from '../../PageHeader';
import Error from '../../Error';

currencyCodeJson = Object.keys(currencyCodeJson).map((item) => {
    return { code: currencyCodeJson[item].code }
});

const ECharge = (props) => {
    const {login, SchedulePlans, ScheduleUpdate, schedule_id } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();
    const [plans, transferPlans] = useState({});
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    const [schedule, setSchedule] = useState({ 
        plans_id:'',
        created_by: login.user_id
    });
    
    useEffect(() => {
        props.plansScheduleRequest({
            plans_id: plans.plans_id
        })
    }, [plans]);
    
    const handleChange = (e, key) => {
        const {name, value} = e.target; 
        const object = {...schedule};
        object[name] = value;
        setSchedule(object);
        SchedulePlans.schedules[key].charge_fee = value;
    };
    
    const handleScheduleInfo = (item, key) => {
        if (isEmpty(plans.id) === true) {
            setInfo({
                ...info,
                  content: 'Please Select Plans',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(schedule[`${key}_charge_fee`]) === true) {
            setInfo({
                ...info,
                  content: 'Please Input Charge fee',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        
       setLoading(true);
       const data = {...item};
       data.plans_id = plans.id;
       data.charge_fee = schedule[`${key}_charge_fee`];
       props.updateScheduleRequest(data);
       return;
    }
    
    useEffect(() => {
        const {success, code, message, schedule} = ScheduleUpdate;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true); 
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [ScheduleUpdate]);

    useEffect(() => {
        const {success, code, message} = SchedulePlans;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [SchedulePlans]);

    // search
    if(activeIndex === 0) {
        return (
            <React.Fragment>
                <Row>
                    <Col md={4}>
                    <SPlans 
                        isPublic={true}
                        buttonName={'Next'} 
                        selectedPlansId
                        setActiveIndex={setActiveIndex}
                        transferPlans={transferPlans} 
                        indexNumber={1} 
                    />
                    </Col>
                </Row>
                
            </React.Fragment>
        )
    } 

    // basic 1
    if(activeIndex === 1) {
        return (
            <React.Fragment>
            <PageHeader title={`Register Charge`} show={loading} className={'mb-4'}/>
            {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
            <Card border={'light'} className={'border-0'}>
                <Card.Body>
                <Row>
            <Table responsive="md">
                <thead>
                    <tr>
                        <th></th>
                        <th>Type</th>
                        <th>Price</th>
                        <th>Platform fee(percentage)</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {SchedulePlans.schedules && SchedulePlans.schedules.map((item, key) => {
                    return (
                        <tr key={key}>
                            <td><Image src={'https://placehold.co/25x25'} roundedCircle/></td>
                            <td>{item.type}</td>
                            <td>{item.price}</td>
                            <td>
                                <FormControl name={`${key}_charge_fee`}
                                    type="number" value={item.charge_fee} 
                                    onChange={(event) => handleChange(event, key)} /></td>
                            <td>
                            <Button className={'btn btn-sm btn-primary'}
                            onClick={() => handleScheduleInfo(item, key)}
                        >Submit</Button>
                            </td>
                        </tr>
                    )
                    })}    
                    </tbody>
            </Table>
        </Row>
                </Card.Body>
            </Card>
            </React.Fragment>
        )
    } 
    return (<React.Fragment></React.Fragment>)
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      ScheduleUpdate: state.schedule.update || {},
      SchedulePlans: state.schedule.plans || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateScheduleRequest,
        plansScheduleRequest,
        initScheduleRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ECharge);