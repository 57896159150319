import React, {useState} from 'react';
import './index.scss';
import { bindActionCreators } from 'redux'
import { useNavigate, Link, Outlet } from 'react-router-dom';
import { connect } from 'react-redux'
import Header from '../header/menu';
import { logoutRequest } from '../../db/action/logout';
import { initLoginRequest } from '../../db/action/login';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import {RiDashboardLine, RiAttachmentLine} from 'react-icons/ri';
import {GiPlagueDoctorProfile, GiBuyCard, GiAbstract034} from 'react-icons/gi';
import {BiArrowFromLeft, BiBarChartAlt2, BiBuildingHouse, BiShieldQuarter} from 'react-icons/bi';
import {AiOutlineUpload} from 'react-icons/ai';
import {SiWebmoney, SiHandshake } from 'react-icons/si';
import {CgLogOff} from 'react-icons/cg';
import {FaSellsy} from 'react-icons/fa';
import {TbPageBreak, TbWallet, TbAccessPoint, TbAdjustments, TbAlertSmall, TbUser,
  TbBrandCodepen, TbDialpadOff, TbMapSearch, TbHeartHandshake, TbHandThreeFingers} from 'react-icons/tb';
import {AiOutlineBank} from 'react-icons/ai';

const MenuLayout = (props) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const clearAll = () => {
      props.initLoginRequest();
      props.logoutRequest();
    }
    
    return (
    <>    
        <Header login = {props.login} />
        <Container fluid className={'bg-dark ew-menu'}>
          <Row className={'ew-min-menu'}>
            <Col md={2} className={'bg-light pt-5'}>
            <Card className={'bg-light border-0'}>
                  <ListGroup>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'profile'} className={'text-decoration-none text-dark m-1'} >
                          <TbAlertSmall size={20} className={'m-1'} />Profile
                        </Link>
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'update'} className={'text-decoration-none text-dark m-1'} >
                          <TbUser size={20} className={'m-1'} />Update Password
                        </Link>
                      </h6>
                    </ListGroup.Item>
                  </ListGroup>
            </Card>
            { props.login.is_customer === true && 
              <Card className={'bg-light border-0'}>
                  <ListGroup>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'orders'} className={'text-decoration-none text-dark m-1'} >
                          <TbAccessPoint size={20} className={'m-1'} />Orders
                        </Link>
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'purchase'} className={'text-decoration-none text-dark m-1'} >
                          <TbPageBreak size={20} className={'m-1'} />Purchase
                        </Link>
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'wallet'} className={'text-decoration-none text-dark m-1'} >
                          <TbWallet size={20} className={'m-1'} />Wallet
                        </Link>
                      </h6>
                    </ListGroup.Item>
                  </ListGroup>
              </Card> }
              { props.login.is_admin === true && 
              <Card className={'bg-light border-0'}>
                  <ListGroup>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'categories'} className={'text-decoration-none text-dark m-1'} >
                          <BiBuildingHouse size={20} className={'m-1'} />Categories
                        </Link>
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'charge'} className={'text-decoration-none text-dark m-1'} >
                          <TbHandThreeFingers size={20} className={'m-1'} />Charge
                        </Link>
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'revenue'} className={'text-decoration-none text-dark m-1'} >
                          <TbHeartHandshake size={20} className={'m-1'} />Revenue
                        </Link>
                      </h6>
                    </ListGroup.Item>
                  </ListGroup>
              </Card> }
              { props.login.is_partner === true && 
              <Card className={'bg-light border-0'}>
                  <ListGroup>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'companies'} className={'text-decoration-none text-dark m-1'} >
                          <TbAdjustments size={20} className={'m-1'} />Companies
                        </Link>
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'branch'} className={'text-decoration-none text-dark m-1'} >
                          <TbBrandCodepen size={20} className={'m-1'} />Branch
                        </Link>
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'plans'} className={'text-decoration-none text-dark m-1'} >
                          <TbDialpadOff size={20} className={'m-1'} />Plans
                        </Link>
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'schedule'} className={'text-decoration-none text-dark m-1'} >
                          <TbMapSearch size={20} className={'m-1'} />Schedule
                        </Link>
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6>
                        <Link to={'earnings'} className={'text-decoration-none text-dark m-1'} >
                          <TbHeartHandshake size={20} className={'m-1'} />Earnings
                        </Link>
                      </h6>
                    </ListGroup.Item>
                  </ListGroup>
              </Card> } 
              <Card className={'bg-light border-0 mt-5'}>
                  <ListGroup>
                    <ListGroup.Item className={'bg-light border-0'}>
                      <h6 onClick={() => clearAll()}>
                        <Link to={'#'} className={'text-decoration-none text-dark m-1'} >
                          <BiBuildingHouse size={20} className={'m-1'} />Sign Off
                        </Link>
                      </h6>
                    </ListGroup.Item>
                  </ListGroup>
              </Card>
            </Col>
            <Col md={10} className={'bg-white'}>
                  <Outlet />
            </Col>
          </Row>
        </Container>
    </>
    );
}

const mapStateToProps = (state) => {
  return {
    logout: state.logout,
    login: state.login
  }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
      logoutRequest,
      initLoginRequest
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuLayout);