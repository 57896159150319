import React, {useState, useEffect} from 'react';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {FiEdit, FiTrash} from 'react-icons/fi';
import Confirmation from '../../Confirmation';
import { listScheduleRequest, removeScheduleRequest } from '../../../db/action/schedule';
import PageHeader from '../../PageHeader';
import Error from '../../Error';
const LSchedule = (props) => {
    const {ScheduleList, ScheduleDelete, setIndex, setScheduleId, login} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    useEffect(() => {
        setLoading(true);
        props.listScheduleRequest({created_by: login.user_id});
    }, []);

    useEffect(() => {
        const {success, code, message} = ScheduleList;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [ScheduleList]);

    useEffect(() => {
        const {success, code, message} = ScheduleDelete;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
            props.listScheduleRequest({created_by: login.user_id});
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [ScheduleDelete]);

    if (ScheduleList.scheduleList && ScheduleList.scheduleList.length === 0) {
        return (<Confirmation title={'My Schedule'} type={'LSchedule'} setIndex={setIndex} />);
    }

    return (
        <React.Fragment>
        <PageHeader title={'My Schedule'} show={loading} className={'mb-4'}/>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <Card>
            <Card.Header className={'p-2 bg-white text-end'}>
                <Row className={'justify-content-end'}>
                    <Col md={2}>
                    <Button className={'btn btn-sm btn-primary text-right'} onClick={() => setIndex(1)}><small>Add Schedule</small></Button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
        <Row>
            <Table responsive="md">
                <thead>
                    <tr>
                        <th></th>
                        <th>Plan</th>
                        <th>Type</th>
                        <th>Price</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {ScheduleList.scheduleList && ScheduleList.scheduleList.map((item, key) => {
                    return (
                        <tr key={key}>
                            <td><Image src={'https://placehold.co/20x20'} roundedCircle/></td>
                            <td>{item.plans.name}</td>
                            <td>{item.type}</td>
                            <td>{item.price}{' ' + item.currency}</td>
                            <td onClick={() => {setIndex(1);setScheduleId(item.id)}}><FiEdit /></td>
                            <td onClick={() => props.removeScheduleRequest({
                                schedule_id: item.id,
                                is_deleted: true
                            })}><FiTrash /></td>
                        </tr>
                    )
                    })}    
                    </tbody>
            </Table>
        </Row>
        </Card.Body>
        </Card>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      ScheduleList: state.schedule.list || {},
      ScheduleDelete: state.schedule.remove || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        listScheduleRequest,
        removeScheduleRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(LSchedule);