import {call, put, takeLatest} from 'redux-saga/effects';
import {UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS} from '../../actionTypes/updatePassword';
import { updatePassword } from '../../../com/user';

function* fetchForgetPassword(params) {
    const json = yield call(updatePassword, params.payload);   
    yield put({ type: UPDATE_USER_SUCCESS, payload: json.data});
}

function* updatePasswordSaga() {
    yield takeLatest(UPDATE_USER_REQUEST, fetchForgetPassword)
}

export default updatePasswordSaga;