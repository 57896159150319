import { UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS
} from '../../actionTypes/updatePassword';

export const updatePasswordRequest = (params) => {
return {
  type: UPDATE_USER_REQUEST,
  payload:params
};
};

export const updatePasswordSuccess = () => {
return {
  type: UPDATE_USER_SUCCESS
};
};
