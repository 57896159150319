import { all, fork } from 'redux-saga/effects';
import tokenSaga from '../db/saga/token';
import loginSaga from '../db/saga/login';
import registerUserSaga from '../db/saga/registerUser';
import forgetPasswordSaga from '../db/saga/forgetPassword';
import updatePasswordSaga from '../db/saga/updatePassword';
import activateUserSaga from '../db/saga/activateUser';
import generateOtpSaga from '../db/saga/generateOtp';
import { getFileSaga, removeFileSaga, uploadFileSaga, insertFileConnectSaga, 
removeFileConnectSaga, getFileConnectSaga, userFileConnectSaga } from '../db/saga/file'; 
import {emailByUserSaga} from '../db/saga/user';
import { getPlaceSaga } from '../db/saga/place';
import { getProfileSaga, insertProfileSaga, updateProfileSaga } from '../db/saga/profile';
import conversionSaga from '../db/saga/conversion';
import { insertPurchaseSaga, userPurchaseSaga, getPurchaseSaga, feePurchaseSaga, updatePurchaseSaga } from '../db/saga/purchase';
import { interestedCompaniesSaga, removeCompaniesSaga, updateCompaniesSaga,
  listCompaniesSaga, userCompaniesSaga, insertCompaniesSaga, getCompaniesSaga } from '../db/saga/companies';
  import { removeCategoriesSaga, listCategoriesSaga,
    searchCategoriesSaga, insertCategoriesSaga, getCategoriesSaga, updateCategoriesSaga } from '../atom/categories/saga';
    import { removeInterestSaga, listInterestSaga,
      partnersInterestSaga, insertInterestSaga, getInterestSaga } from '../db/saga/interest';
    import { removeBranchSaga, listBranchSaga, userBranchSaga,
      companiesBranchSaga, insertBranchSaga, getBranchSaga, updateBranchSaga, searchBranchSaga } from '../db/saga/branch';
      import { removePlansSaga, listPlansSaga, branchPlansSaga, searchPlansSaga,
                insertPlansSaga, getPlansSaga, updatePlansSaga } from '../db/saga/plans';
        import { removeScheduleSaga, listScheduleSaga,
          plansScheduleSaga, insertScheduleSaga, getScheduleSaga, updateScheduleSaga } from '../db/saga/schedule';
      
  import { insertDomainSaga, TypeDomainSaga, 
    getDomainSaga, userDomainSaga,updateDomainSaga,
    removeDomainSaga, listDomainSaga} from '../db/saga/domain';
import { insertWalletHistorySaga, insertWalletSaga, 
  getWalletSaga, userWalletHistorySaga, userWalletSaga,
  updateWalletSaga } from '../db/saga/wallet';
  import { businessEarningsHistorySaga, businessEarningsSaga,
    } from '../db/saga/earnings';
    import { adminRevenueHistorySaga, adminRevenueSaga,
    } from '../db/saga/revenue';
import { insertTransactionSaga, getTransactionSaga, userTransactionSaga, updateTransactionSaga } from '../db/saga/transaction';

import { insertCashfreeSaga } from '../atom/cashfree/saga';


export default function* rootSaga() {
  yield all([
    fork(businessEarningsHistorySaga),
    fork(businessEarningsSaga),
    fork(insertCashfreeSaga),
    fork(searchBranchSaga),
    fork(getPlaceSaga),
    fork(removeScheduleSaga), 
    fork(listScheduleSaga),
    fork(plansScheduleSaga), 
    fork(insertScheduleSaga), 
    fork(getScheduleSaga), 
    fork(updateScheduleSaga),
    fork(insertPlansSaga),
    fork(updatePlansSaga),
    fork(removePlansSaga),
    fork(getPlansSaga),
    fork(branchPlansSaga),
    fork(searchPlansSaga),
    fork(listPlansSaga),
    fork(removeInterestSaga),
    fork(listInterestSaga),
    fork(partnersInterestSaga), 
    fork(insertInterestSaga), 
    fork(getInterestSaga),
    fork(insertDomainSaga), 
    fork(TypeDomainSaga), 
    fork(getDomainSaga), 
    fork(userDomainSaga),
    fork(updateDomainSaga),
    fork(removeDomainSaga), 
    fork(listDomainSaga),
    fork(interestedCompaniesSaga), 
    fork(removeCompaniesSaga), 
    fork(updateCompaniesSaga),
    fork(listCompaniesSaga), 
    fork(userCompaniesSaga), 
    fork(insertCompaniesSaga), 
    fork(getCompaniesSaga),
    fork(removeCategoriesSaga), 
    fork(searchCategoriesSaga),
    fork(listCategoriesSaga),
    fork(updateCategoriesSaga), 
    fork(insertCategoriesSaga), 
    fork(getCategoriesSaga),
    fork(userBranchSaga),
    fork(removeBranchSaga), 
    fork(listBranchSaga),
    fork(companiesBranchSaga), 
    fork(insertBranchSaga), 
    fork(updateBranchSaga),
    fork(getBranchSaga),
    fork(insertPurchaseSaga), 
    fork(userPurchaseSaga), 
    fork(getPurchaseSaga), 
    fork(feePurchaseSaga), 
    fork(updatePurchaseSaga),
    fork(insertWalletHistorySaga), 
    fork(insertWalletSaga), 
    fork(getWalletSaga), 
    fork(userWalletHistorySaga), 
    fork(userWalletSaga),
    fork(updateWalletSaga),
    fork(insertTransactionSaga), 
    fork(updateTransactionSaga),
    fork(getTransactionSaga), 
    fork(userTransactionSaga),
    fork(tokenSaga),
    fork(loginSaga),
    fork(registerUserSaga),
    fork(forgetPasswordSaga),
    fork(updatePasswordSaga),
    fork(activateUserSaga),
    fork(generateOtpSaga),
    fork(emailByUserSaga),
    fork(removeFileSaga),
    fork(uploadFileSaga),
    fork(getFileSaga),
    fork(insertFileConnectSaga),
    fork(removeFileConnectSaga), 
    fork(getFileConnectSaga), 
    fork(userFileConnectSaga),
    fork(conversionSaga),
    fork(getProfileSaga),
    fork(insertProfileSaga),
    fork(updateProfileSaga),
    fork(adminRevenueHistorySaga), 
    fork(adminRevenueSaga)
  ]);
}
