import React, {useState} from 'react';
import './index.scss';
import logoPng from '../../asset/images/logo.png';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import UpdatePassword from '../../components/UpdatePassword';
const UpdatePasswordLayout = (props) => {
    const [show, setShow] = useState(false);
    return (
    <Container fluid className={'ew-update-password-layout'}>
        <Row className={'align-items-center mt-5'}>
            <Col md={12}>
                <Row>
                    <Col md={3}>
                        <UpdatePassword />
                    </Col>   
                </Row>
            </Col>
        </Row>
    </Container>
    );
}

export default UpdatePasswordLayout;